<template>
  <validation-observer ref="simpleRules">
    <pre-loading v-if="loading" />
    <item-form
      v-else
      :submit-form="submitForm"
    />
    <pre>{{ itemData }}</pre>
  </validation-observer>
</template>

<script>

import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ItemForm from '@/views/Admin/Products/ItemForm.vue'
import PreLoading from '@/layouts/components/common/PreLoading.vue'

export default {
  name: 'Add',
  components: {
    PreLoading,
    ValidationObserver,
    ItemForm,
  },
  data() {
    return {
      loading: true,
      required,
    }
  },
  computed: {
    itemData() {
      return this.$store.getters['products/dataItem']
    },
    saveData() {
      return this.$store.getters['products/dataSaveStatus']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
        this.$store.commit('products/RESET_DATA_ITEM', true)
        this.$refs.simpleRules.reset()
        this.$router.push('/app/products/pending')
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
    },
  },
  created() {
    this.$store.commit('products/RESET_DATA_ITEM', true)
    this.apiProduct()
    localize('tr')
  },
  methods: {
    apiProduct() {
      this.$store.dispatch('apiProducts/getDataItem', this.$route.params.id)
        .then(response => {
          if (response) {
            this.itemData.primary_pcode = response.anamamulkod
            this.itemData.pcode = response.mamulkod
            this.itemData.width = response.sen
            this.itemData.weight = response.sgr
            this.itemData.id_product_qualities = response.id_product_qualities
            this.itemData.tests.asinma = response.asinma
            this.itemData.tests.asinma_sonuc = response.asinma_sonuc
            this.itemData.tests.pilling = response.pilling
            this.itemData.tests.pilling_sonuc = response.pilling_sonuc
            this.itemData.tests.rh_kuru = response.rh_kuru
            this.itemData.tests.rh_yas = response.rh_yas
            this.itemData.tests.hav_kaybi = response.hav_kaybi
            this.loading = false
          }
        })
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.$store.dispatch('products/saveData',this.itemData)
        } else {
          this.showToast({
            title: 'Uyarı',
            icon: 'InfoIcon',
            text: 'Lütfen tüm alanları doldurun.!',
            variant: 'warning',
          })
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      }, {
        position: 'bottom-center',
      })
    },
  },
}
</script>
